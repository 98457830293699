<template>
  <div class="container">
    <div  style="margin: 10px;">
      <div v-if="screenSize < 1000" class="news-video-full alert alert-secondary" style="width: 100% !important;">
            <iframe class="iframe-video-full" scrolling="no" frameborder="0" style="border: none;" :src="selectedVideo.bitchute"></iframe>
            <div style="margin: 10px;">
              <button type="button" class="btn btn-primary back" @click="back()">BACK</button>
            </div>
      </div>
      <div v-if="screenSize > 1000" class="news-video-full alert alert-secondary" style="width: 100% !important;">
          <div style="padding: 20px;">
            <iframe class="iframe-video-full" scrolling="no" frameborder="0" style="border: none;" :src="selectedVideo.bitchute"></iframe>
            <div style="margin: 20px;">
              <button type="button" class="btn btn-primary back" @click="back()">BACK</button>
            </div>
          </div>
      </div>
      <img src="../assets/Bar.jpg" class="divider-bar">
      <div  class="news-caption-full" style="padding: 5px 0px 5px 0px; display: block !important; width: 100% !important;">
        <div class="alert alert-secondary" style="width: 100% !important">
          <h1 class="video-title"><strong>{{selectedVideo.title}}</strong></h1>
          <p><em>{{formatDate(selectedVideo.date)}}</em></p>
          <h6>For fullscreen visit BitChute or Rumble.  Rumble will be highest resolution.</h6>
          <p>
            <a style="padding: 20px;" v-if="selectedVideo.bitchute" :href="getBitchuteLink(selectedVideo.bitchute)" target="_blank">BitChute</a>
            <a style="padding: 20px;" v-if="selectedVideo.rumbleHtml" :href="selectedVideo.rumbleHtml" target="_blank">Rumble</a>
          </p> 
          <p style="text-align: left; white-space: pre-wrap;" v-html="evaluateDescription(selectedVideo.description)"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import * as ts from "typescript";

export default {
  name: 'VideoSelection',
  props: {
    screenSize: Number,
    title: String,
    selectedVideo: Object
  },
  computed: {},
  methods: {
    openLink: function (link) {
      window.open(link);
    },
    evaluateDescription: function(description){
      let descriptionReplaced = description.replace('/n', '<BR>')
      return descriptionReplaced
    },
    back: function () {
      this.$emit('video-selection', null)
    },
    getBitchuteLink(url){
      return url.replace('embed', 'video');
    },
    formatDate: function(date){
      return date.toDateString();
    },
    getRoles: function () {
      axios
                    .get("/api/v1/roles")
                    .then(response => (this.roles = response.data))
            },
            postRole: function () {
                // Creating
                if (this.role_id == '' || this.role_id == null) {
                    axios
                        .post("/api/v1/roles", {
                            "name": this.role_name,
                        })
                        .then(savedRole => {
                            this.roles.push(savedRole.data);
                            this.role_name = '';
                            this.role_id = '';
                        })
                } else { // Updating
                    axios
                        .post("/api/v1/roles", {
                            "id": this.role_id,
                            "name": this.role_name,
                        })
                        .then(() => {
                            this.getRoles();
                            this.role_name = '';
                            this.role_id = '';
                        })
                }
            },
            editRole: function (role) {
                this.role_id = role.id;
                this.role_name = role.name;
                document.getElementById('roleForm').setAttribute("class", document.getElementById('roleForm').getAttribute("class") + " show");
            },
            deleteRole: async function (role) {
                await axios
                    .delete("/api/v1/roles/" + role.id);
                this.getRoles();
            }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
