<template>
  <div class="container">
    <div>
      <div v-if="this.screenSize < 1000" class="news-caption" style="padding: 5px 0px 5px 0px; display: block !important;">
        <div class="alert alert-secondary">
          <a style="padding: 20px 0px 20px 0px" @click="viewArticle(video)" target="_blank">{{title.toUpperCase()}}</a>
          <div style="margin: 10px;">
            <button type="button" class="btn btn-primary view" @click="viewArticle(video)">View Video</button>
          </div>
        </div>
      </div>
      <div v-if="this.screenSize > 1000" class="news-caption" style="border: 1px solid transparent !important; padding: 2px !important;">
        <div class="alert alert-secondary" style="border: none !important;">
          <a @click="viewArticle(video)" target="_blank">{{title.toUpperCase()}}</a>
          <div style="margin: 10px;">
            <button style="display: block; margin: auto; vertical-align: middle;" type="button" class="btn btn-primary" @click="viewArticle(video)">View Video</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="news-video">
        <div class="alert alert-secondary">
          <iframe class="iframe-video" scrolling="no" frameborder="0" :src="bitchute"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Video',
  props: {
    screenSize: Number,
    title: String,
    description: String,
    bitchute: String,
    rumble: String,
    categories: Array,
    date: Date,
    bundledVideos: Array,
    video: Object
  },
  computed: {},
  methods: {
    openLink: function (link) {
      window.open(link);
    },
    titleCase: function(title){
            return title.toUpperCase();
    },
    viewArticle: function () {
      console.warn("emitting this.video: ", this.video)
      this.$emit('video-selection', this.video)
    },
    getBitchuteLink(bitchute){
      return bitchute.replace('embed', 'video');
    },
    getRoles: function () {
      axios
                    .get("/api/v1/roles")
                    .then(response => (this.roles = response.data))
            },
            postRole: function () {
                // Creating
                if (this.role_id == '' || this.role_id == null) {
                    axios
                        .post("/api/v1/roles", {
                            "name": this.role_name,
                        })
                        .then(savedRole => {
                            this.roles.push(savedRole.data);
                            this.role_name = '';
                            this.role_id = '';
                        })
                } else { // Updating
                    axios
                        .post("/api/v1/roles", {
                            "id": this.role_id,
                            "name": this.role_name,
                        })
                        .then(() => {
                            this.getRoles();
                            this.role_name = '';
                            this.role_id = '';
                        })
                }
            },
            editRole: function (role) {
                this.role_id = role.id;
                this.role_name = role.name;
                document.getElementById('roleForm').setAttribute("class", document.getElementById('roleForm').getAttribute("class") + " show");
            },
            deleteRole: async function (role) {
                await axios
                    .delete("/api/v1/roles/" + role.id);
                this.getRoles();
            }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
