<template>
    <div class="container  text-center" style="clear: both; padding-top: 10px; text-align: center !important;">
      <img src="../assets/Bar.jpg" class="divider-bar">
      <div class="row">
        <div class="col-sm-12">
          <a :href="'mailto:' + contact">Contact</a>
          <!-- <a :href="about">About</a> -->
        </div>
      </div>
      <div class="row" style="padding: 5px">
        <div class="col-sm-12">
          The Fifth Quadrant Copyright 2022 to present.
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Footer',
  props: {
    contact: String
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
