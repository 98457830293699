<template>
    <div style="margin-bottom: 75px;">
        <div v-if="loaded">
            <!-- <div>
                <button v-on:click="toggleSort()">
                    <span v-if="ascending">ASC</span>
                    <span v-else>DESC</span>
                </button>
              </div> -->
            <div v-if="this.selectedVideo != null">
                <video-selection v-bind:screenSize="screenSize" v-bind:selectedVideo="selectedVideo" @video-selection="updateSelectedVideo"></video-selection>
            </div>  
            <div v-if="this.selectedVideo == null">
                <div v-if="filteredVideos.length">
                    <div class="container">
                        <div v-if="screenSize < 1000">
                            <div class="card" v-for="(video, idx) in filteredVideos" v-bind:key="idx" style="padding: 0px !important;">
                                <div class="card-body" style="display: block !important; padding: 0px !important;"> 
                                    <Video v-bind:screenSize="screenSize" v-bind:category="video.category" v-bind:title="video.title" v-bind:description="video.description" v-bind:bitchute="video.bitchute" v-bind:date="video.date" v-bind:video="video" @video-selection="updateSelectedVideo"></Video>
                                </div>
                            </div>
                        </div>
                        <div v-if="screenSize >= 1000">
                            <div class="container col-sm-12 col-md-12 col-lg-12" v-for="(videoRow, idx) in filteredVideos" v-bind:key="idx" style="display: block !important; padding: 0px !important;">
                                <div class="row col-sm-12 col-md-12 col-lg-12" style="padding: 0px !important; margin: 0px !important; display: block;">
                                    <div class="card col-sm-6 col-md-6 col-lg-6" v-for="(video, video_idx) in videoRow" v-bind:key="video_idx" style="padding: 0px !important; margin: 0px !important; display: inline-block;">
                                            <div class="card-body" style="padding: 0px !important; margin: 0px !important; display: inline-block;"> 
                                                <Video style="display: inline-block;" v-bind:screenSize="screenSize" v-bind:title="video.title" v-bind:description="video.description" v-bind:bitchute="video.bitchute" v-bind:date="video.date" v-bind:video="video" @video-selection="updateSelectedVideo"></Video>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!filteredVideos.length">
                    <div class="container">
                        <div class="row col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <h2 style="padding-top: 20px;">No results exist for the search criteria: {{videoSearch}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loaded">
            <div>
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
// import moment from 'moment';
import Video from './Video.vue';
import VideoSelection from './VideoSelection.vue';

export default {
  name: 'Videos',
  components: {
    Video,
    VideoSelection
  },
  props: {
        screenSize: Number,
        order: String,
        videoSearch: String,
        selectedVideo: Object
  },
  created: function (){
      if(window.innerWidth < 1400){
        this.bundleVideosSetsOfTwo();
      } else {
        this.bundleVideosSetsOfThree();
      }
  },
  data () {
      return {
    loaded: false,    
    ascending: false,
    dateOrder: -1,
    videoSelection: '',
    categories: [
        {1: 'MapleAndTheBlobs'},
        {2: 'TheNabsta'},
        {3: 'JackAndFred'},
        {4: 'HunterTheSailor'},

    ],
    videos: [
            {
                title: 'Hunter the Sailor: The Best Sailor in the Midwest',
                description: '\nWatch Hunter the Sailor as he embarks up fantastical sailing journeys throughout the midwest.',
                bitchute: 'https://www.bitchute.com/embed/CtRx5VOh2rC3/',
                rumble: 'https://rumble.com/embed/v1z1idw/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v23k8hk-hunter-the-sailor-the-best-sailor-in-the-midwest.html',
                fileName: "Flying_Revival_Rituals.m4v",
                date: new Date('2023/01/02'),
                categories: [2, 4]
            },
            {
                title: 'Flying Revival Rituals Children\'s Book & Fable Must See Twist at the End!',
                description: '\nPlease share with your friends and let me know what you think. Your feedback is greatly appreciated.\n\nFor every comment you leave I will leave a comment on one of your videos.\n\nWatch Clemens read a children\'s fable to his class about the dangers of doing Flying Revival Rituals!!! Please be sure to watch it before sharing with your children ;)',
                bitchute: 'https://www.bitchute.com/embed/JS8IsYWrFt1F/',
                rumble: 'https://rumble.com/embed/v1z1idw/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v21nna6-flying-revival-rituals-childrens-book-and-fable-must-see-twist-at-end.html',
                fileName: "Flying_Revival_Rituals.m4v",
                date: new Date('2022/12/19'),
                categories: [2]
            },
            {
                title: 'The Basic Components of a Computer',
                description: '\nAn animation that goes through the basic components of a desktop computer used to build a computer.',
                bitchute: 'https://www.bitchute.com/embed/th4qSuhX0vs1/',
                rumble: 'https://rumble.com/embed/v1yhg9w/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v213l6q-the-basic-components-of-a-computer.html',
                fileName: "Basic_Components_of_a_Computer.m4v",
                date: new Date('2022/12/19'),
                categories: [2]
            },
            {
                title: 'Adventures of Jack & Fred Episode 1',
                description: '\nJack & Fred decide to go on an adventure.',
                bitchute: 'https://www.bitchute.com/embed/25SauUHzKiOX/',
                rumble: 'https://rumble.com/embed/v1yhbow/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v213glq-adventures-of-jack-and-fred-episode-1.html',
                fileName: "Jack_And_Fred_Episode_1.m4v",
                date: new Date('2022/12/17'),
                categories: [3, 2]
            },
            {
                title: 'Maple & The Blobs: Light-Speed Gaming',
                description: '\nThe blobs decide they they\'d rather play video games than do their homework. They come up with a brilliant idea to go travel faster than the speed of light so time slows down giving them more time to play video games. They come back to a surprise when they return.',
                bitchute: 'https://www.bitchute.com/embed/rog114Wlzz7M/',
                rumble: 'https://rumble.com/embed/v1p6624/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v1rsbu2-maple-and-the-blobs-light-speed-gaming.html',
                fileName: "Light_Speed_Gaming.m4v",
                date: new Date('2022/11/05'),
                categories: [1, 2]
            },
            {
                title: 'Angry Blobs Discuss Lady Gaga Played on Iowa State University Campanile',
                description: '\nIowa State University played Lady Gaga\'s Bad Romance on the Campanile. Here is what the Angry Blobs had to say about it.',
                bitchute: 'https://www.bitchute.com/embed/O41gPyrU7bg8/',
                rumble: 'https://rumble.com/embed/v1kygrg/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v1nkml2-angry-blobs-discuss-lady-gaga-played-on-iowa-state-university-campanile.html',
                fileName: "Diversity_Equity_And_Inclusion_Activist_Turn_It_Off.m4v",
                date: new Date('2022/10/03'),
                categories: [1, 2]
            },
            {
                title: 'Getting the Book Invented Properly - Douglas Adams',
                description: '\nAuthor of the Hitchhiker\'s Guide to the Galaxy, Douglas Adams discusses the process of getting the book invented properly.',
                bitchute: 'https://www.bitchute.com/embed/UzpgTnWA0zjA/',
                rumble: 'https://rumble.com/embed/v1npbvu/?pub=1h7zlq',
                rumbleHtml: 'https://rumble.com/v1qbhn8-getting-the-book-invented-properly-douglas-adams.html',
                fileName: "Getting_the_Book_Invented_Properly_Douglas_Adams.m4v",
                date: new Date('2022/10/03'),
                categories: [1, 2]
            },
        ],
        bundledVideos: [],
    }
    },
  computed: {
        filteredVideos(){
            if(this.screenSize < 1000){
                return this.filteredList();
            }
            else if(window.innerWidth < 1400){
                return this.bundleVideosSetsOfTwo();
            } else {
                return this.bundleVideosSetsOfTwo();
                // return this.bundleVideosSetsOfThree();
            }
        },
        sortByDate(){
            return null;
            // return this.filteredList.sort((a, b) => new a.date - b.date)
        }
  },
//   filters: 
//   {
//         sortDates: function() {
//             var order = this.ascending ? -1 : 1;
//             // `this` points to the vm instance    
//             this.videos.sort(function(a, b) {
//                 a = a.date;
//                 b = b.date;
//                 var results = a > b ? -1 : a < b ? 1 : 0;
//                 return results * order;
//             });
//         },
//   },
    methods: 
    {
        openLink: function(link) 
        {
            window.open(link);
        },
        filteredList() {
            // this.videos = this.videos.sort(function(a, b) {
            //     a = a.date;
            //     b = b.date;
            //     var results = a > b ? -1 : a < b ? 1 : 0;
            //     return results * this.dateOrder;
            // });
            return this.videos.filter(video => {
                // console.warn("video.title.toLowerCase()", video.title.toLowerCase())
                // console.warn("Video Search: ", this.videoSearch)
                // console.warn("IS INCLUDED: ", video.title.toLowerCase().includes(this.videoSearch.toLowerCase()))
                // console.warn("Should Return: ", (this.videoSearch == '' || video.title.toLowerCase().includes(this.videoSearch.toLowerCase())))
                return (this.videoSearch == '' || video.title.toLowerCase().includes(this.videoSearch.toLowerCase()) || video.description.toLowerCase().includes(this.videoSearch.toLowerCase()))
            })
        },
        titleCase: function(title){
            return title.toUpperCase();
        },
        updateSelectedVideo: function (videoSelection) {
            console.warn("Emitted video: ", videoSelection)

            this.$emit('video-selection', videoSelection)
        },
        bundleVideosSetsOfTwo: function()
        {
            this.loaded = false;
            this.bundledVideos = [];
            let currentRow = [];
            // console.warn("Videos: ", this.videos);
            let filteredList = this.filteredList();
            // console.warn("Filtered LIst: ", filteredList)
            // console.warn("Filtered LIST LENGTH: ", filteredList.length)


            filteredList.forEach((video, index) => 
            {
                // console.warn(index)
                let lastVideo = (index + 1) == filteredList.length;
                // // Coupling videos for rows of two
                // console.warn("LAST VIDEO: ", lastVideo)
                // console.warn()
                if((index == 0 || index%2 != 1) && !lastVideo)
                {
                    currentRow = [video]
                } 
                else if ((index == 0) && lastVideo) {
                    currentRow = [video]
                    this.bundledVideos.push(currentRow)                    
                }
                else if ((index%2 != 1) && lastVideo) {
                    currentRow = [video]
                    this.bundledVideos.push(currentRow)                    
                }
                else 
                {
                    currentRow.push(video)
                    this.bundledVideos.push(currentRow)
                }
            })
            this.loaded = true;
            return this.bundledVideos;
        },
        bundleVideosSetsOfThree: function()
        {
            this.loaded = false;
            this.bundledVideos = [];
            let currentRow = [];
            let filteredList = this.filteredList();
            // console.warn("Videos: ", filteredList);

            filteredList.forEach((video, index) => 
            {
                // // Coupling videos for rows of two
                let lastVideo = (index + 1) == filteredList.length;
                if(index%3 == 0 && !lastVideo)
                {
                    if(index != 0){
                        this.bundledVideos.push(currentRow)
                    }
                    currentRow = [video]
                } else if (index%3 == 0 && lastVideo) {
                    this.bundledVideos.push(currentRow)
                    currentRow = [video]
                    this.bundledVideos.push(currentRow)           
                } else if (index%3 == 1 && lastVideo) {
                    currentRow = [video]
                    this.bundledVideos.push(currentRow)                    
                } else if (index%3 == 2 && lastVideo) {
                    currentRow.push(video)
                    this.bundledVideos.push(currentRow)                    
                }
                else 
                {
                    currentRow.push(video)
                }
            })
            this.loaded = true;
            return this.bundledVideos;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
