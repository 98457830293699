<template>
  <div class="container">
    <img class="logo" alt="The Fifth Quadrant" src="../assets/The-Fifth-Quadrant-Logo-Drop-Shadow-Stacked-Web.jpg" style="width: 100% !important;">
    <img v-if="this.screenSize < 1000" class="logo" alt="THE FINAL ONE" src="../assets/TheFinalOne.png" alt-text="THE FINAL ONE" style="padding:5px 10px 5px 0px; width: 100% !important;">
    <img v-if="this.screenSize >= 1000" class="logo" alt="THE FINAL ONE" src="../assets/TheFinalOne.png" alt-text="THE FINAL ONE" style="padding:5px 0px 5px 0px; width: 100% !important;">
    <img src="../assets/Bar.jpg" class="divider-bar">
  </div>
</template>

<script>

export default {
  name: 'Header',
  components: {
  },
props: {
  screenSize: Number,
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
<!-- Nabsta4175TheFifthQuadrant## -->
