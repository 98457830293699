<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light"></nav>
</template>

<script>

export default {
  name: 'Navigation',
  props: {
    selection: String
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.padding-20-all{
  padding: 20px;
}

.padding-20-bottom{
  padding: 0px 0px 20px 0px;
}
</style>
