<template>
    <div>
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h1>About The Fifth Quadrant</h1>
                                <p>The Fifth Quadrant is the realm of the fifth and final quadrant.  There are rumors of a sixth and seventh quadrant, but those are just conspiracies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
// import moment from 'moment';

export default {
  name: 'About',
  components: {},
  props: {
        mobile: Boolean,
  },
//   data () {},
    computed: {
    },
    methods: 
    {
        openLink: function(link) 
        {
            window.open(link);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
